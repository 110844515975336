/* src/styles/EternalThings.css */

.eternal-things header {
    text-align: center;
    color: #ffcc00;
    padding: 20px;
    background-color: #333;
}

.eternal-things .section {
    width: 90%;
    max-width: 800px;
    margin: 20px auto;
    background-color: #333;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.eternal-things h2 {
    font-size: 1.5em;
    color: #6ee86e;
}

.eternal-things .item {
    margin: 20px 0;
    background-color: #444;
    padding: 15px;
    border-radius: 8px;
}

.eternal-things .item h4 {
    color: #ffcc00;
}

.eternal-things p {
    color: #e0e0e0;
}
