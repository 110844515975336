/* Основные стили для хедера */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #333;
  color: #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  position: relative; /* Оставляем, чтобы кнопки оставались на месте */
  z-index: 1000; /* Выше карты */
}

/* Левая часть — логотип */
.logo a {
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  color: #00ff41;
}

/* Правая часть — гамбургер + кнопки входа */
.right-section {
  display: flex;
  align-items: center;
  gap: 20px;
}
/* Гамбургер-меню */
.hamburger-container {
  display: block;
  position: relative; /* Привязываем меню к контейнеру */
  z-index: 2100;
}

.hamburger-button {
  background: none;
  border: none;
  font-size: 28px;
  color: #00ff41;
  cursor: pointer;
  padding: 10px;
  z-index: 2100; /* Кнопка остается выше таблицы */
}

/* Меню теперь привязано к кнопке */
.hamburger-menu {
  display: none;
  position: absolute; /* Привязываем к контейнеру */
  top: 100%; /* Выпадает под кнопкой */
  left: 100%; /* Смещаем вправо на ширину кнопки */
  background-color: rgba(51, 51, 51, 0.95);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  z-index: 2000;
  min-width: 180px;
  max-height: 80vh;
  overflow-y: auto;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* При открытии меню */
.hamburger-menu.active {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

/* Плавное появление */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Ссылки в меню */
.hamburger-menu a {
  display: block;
  color: #00ff41;
  text-decoration: none;
  padding: 10px;
  font-size: 16px;
  white-space: nowrap; /* Чтобы текст не переносился */
}

.hamburger-menu a:hover {
  background-color: #444;
  color: #ffcc00;
  border-radius: 4px;
}

/* Гамбургер-меню теперь работает независимо от ширины экрана */
.hamburger-menu.active {
  display: block;
}


/* Кнопки входа и выхода */
.auth-buttons {
  display: flex;
  gap: 10px;
}

.auth-buttons .login-button,
.auth-buttons .signup-button {
  padding: 5px 10px;
  font-size: 14px;
  color: white;
  border: none;
  border-radius: 4px;
}

.auth-buttons .login-button {
  background-color: #007bff;
}

.auth-buttons .signup-button {
  background-color: #28a745;
}

.game-button {
  font-size: 18px;
  font-weight: bold;
  color: #00ff41;
  text-decoration: none;
  margin-left: 20px;
  transition: color 0.3s ease;
}

.game-button:hover {
  color: #ffcc00;
}


.account-button,
.account-button:visited {
    color: #00ff41 !important; /* Принудительно зеленый цвет */
}
